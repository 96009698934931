export const useGraphql = async (
  query,
  variablesInput,
  options = { executeSSR: true }
) => {
  const data = ref(null)
  const loading = ref(false)

  // If the variables parameter isn't reactive, make it reactive
  const variables = isRef(variablesInput) ? variablesInput : ref(variablesInput)

  const executeQuery = async (variables) => {
    try {
      loading.value = true
      const result = await useAsyncQuery(query, variables, {
        fetchPolicy: 'cache-and-network', // Usa la cache e aggiorna i dati in background
      })
      data.value = result.data.value
      data.value = result.data.value
      loading.value = false
    } catch (e) {
      console.error(e)
      loading.value = false
    }
  }

  // Execute the query immediately
  if (options.executeSSR) {
    await executeQuery(variables.value)
  }

  // Watch for changes in the variables and execute the query again if they change
  watch(
    () => variables.value,
    (newVariables) => executeQuery(newVariables)
  )

  return { data, loading }
}
